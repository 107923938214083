export function customSortMixChars(arrayObject, field, order = 1) {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });
  arrayObject?.sort((val1, val2) => {
    const value1 = val1[field] != null ? val1[field] : "";
    const value2 = val2[field] != null ? val2[field] : "";
    let result;

    result = collator.compare(value1, value2);
    return order * result;
  });
  return arrayObject;
}
