<template>
  <v-container fluid class="down-top-padding">
    <div class="janus">
      <v-toolbar flat class="mb-4 px-3">
        <v-text-field
          outlined
          v-model="search"
          @input="searchParticipants()"
          append-icon="mdi-magnify"
          :label="$t('lead.search')"
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="
            initialize();
            search = '';
          "
          >{{ $t("janusDashboard.refresh") }}</v-btn
        >
      </v-toolbar>
      <v-row>
        <v-col cols="4"
          ><div class="onlinehead">
            {{ $t("janusDashboard.janusOnlineMembers") }} ({{
              online_participants.length
            }})
          </div>
          <span v-for="(item, index) in online_participants" :key="index">
            <div class="online-members" v-html="item.display" /> </span
        ></v-col>
        <v-col cols="4"
          ><div class="connecthead">
            {{ $t("janusDashboard.systemOnlineMembers") }} ({{
              connecting_participants.length
            }})
          </div>
          <div
            class="connecting-members"
            v-for="(item, index) in connecting_participants"
            :key="index"
          >
            <h3 class="mb-1 font-weight-medium">
              <i
                aria-hidden="true"
                class="v-icon notranslate mr-1 mdi mdi-card-account-details theme--light"
                style="font-sizpinal3@example.come: 15px"
              ></i
              >&nbsp;&nbsp;{{ item.user_detail.company_name }} /
              {{ item.user_detail.representative_name }}
            </h3>
            <div class="d-flex align-center">
              <i
                aria-hidden="true"
                class="v-icon notranslate ml-2 mdi mdi-map-marker theme--light"
                style="font-size: 15px"
              ></i
              >{{ item.parent_id ? item.parent_city : item.user_detail.city }}
              <i
                aria-hidden="true"
                class="v-icon notranslate ml-5 mdi mdi-phone-classic theme--light"
                style="font-size: 15px"
              ></i
              >&nbsp;{{
                item.parent_id
                  ? item.user_detail.representative_phone
                  : item.user_detail.company_phone
              }}
            </div>
          </div>
        </v-col>
        <v-col cols="4"
          ><div class="offlinehead">
            {{ $t("janusDashboard.offlineMembers") }} ({{
              offline_participants.length
            }})
          </div>
          <div
            class="offline-members"
            v-for="(item, index) in offline_participants"
            :key="index"
          >
            <h3 class="mb-1 font-weight-medium">
              <i
                aria-hidden="true"
                class="v-icon notranslate mr-1 mdi mdi-card-account-details theme--light"
                style="font-size: 15px"
              ></i
              >&nbsp;&nbsp;{{ item.user_detail.company_name }} /
              {{ item.user_detail.representative_name }}
            </h3>
            <div class="d-flex align-center">
              <i
                aria-hidden="true"
                class="v-icon notranslate ml-2 mdi mdi-map-marker theme--light"
                style="font-size: 15px"
              ></i
              >{{ item.parent_id ? item.parent_city : item.user_detail.city }}
              <i
                aria-hidden="true"
                class="v-icon notranslate ml-5 mdi mdi-phone-classic theme--light"
                style="font-size: 15px"
              ></i
              >&nbsp;{{
                item.parent_id
                  ? item.user_detail.representative_phone
                  : item.user_detail.company_phone
              }}
            </div>
          </div></v-col
        >
      </v-row>
    </div>
  </v-container>
</template>
<script>
// Janus Related files
import { mapActions, mapGetters } from "vuex";
import { yoyojsCall } from "@/plugins/yoyo";
import _ from "lodash";
import EventBus, { ACTIONS } from "../eventbus/index.js";
import { showSnackbar } from "../eventbus/action.js";
import { customSortMixChars } from "../utils/common.utility.js";
export default {
  name: "JanusDashboard",
  data() {
    return {
      search: "",
      online_participants: [],
      offline_participants: [],
      connecting_participants: [],
      previousOnline: [],
    };
  },
  computed: {
    ...mapGetters({
      members: "members/getMembersAndAssociates",
      user: "user/getUser",
    }),
  },
  mounted() {
    yoyojsCall();
    this.initialize();
    EventBus.$on(ACTIONS.PARTICIPANT_GRID, (message) => {
      if (message.audiobridge === "joined" && message.id) {
        this.online_participants = [];
        this.online_participants = message.participants;
        this.online_participants.forEach((val) => {
          val.userId = val.display.split("$$")[1];
        });
      }
      if (message.audiobridge === "joined" && !message.id) {
        const userExists = this.online_participants.some(
          (user) => user.id === message.participants[0].id
        );
        if (!userExists) {
          this.online_participants.push(message.participants[0]);
        }
        this.online_participants.forEach((val) => {
          val.userId = val.display.split("$$")[1];
        });
      }
      if (message.audiobridge === "event" && message.leaving) {
        const userIndex = this.online_participants.findIndex(
          (user) => user.id === message.leaving
        );
        if (userIndex !== -1) {
          this.online_participants.splice(userIndex, 1);
        }
      }

      this.online_participants = customSortMixChars(
        this.online_participants
          .filter((val) => val.userId != this.user.id + "@@" + this.user.email)
          .map(function (user) {
            user.compName = user.display
              .split("&nbsp;&nbsp;")[1]
              .split(" / ")[0];
            user.repName = user.display
              .split("&nbsp;&nbsp;")[1]
              .split(" / ")[1]
              .split("</h3>")[0];
            return user;
          }),
        "compName"
      );
      this.previousOnline = _.cloneDeep(this.online_participants);
      console.log(this.online_participants);
      this.getOfflineMembers();
    });
  },
  methods: {
    ...mapActions({
      getMembersAndAssociates: "members/getMembersAndAssociates",
    }),

    async initialize() {
      try {
        this.online_participants = _.cloneDeep(this.previousOnline);
        await this.getMembersAndAssociates();
        await this.getOfflineMembers();
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      }
    },

    async getOfflineMembers() {
      try {
        this.offline_participants = customSortMixChars(
          this.members
            .filter(
              (elem) =>
                !this.online_participants.find(
                  ({ userId }) => elem.id == userId
                ) && elem.user_token == null
            )
            .map(function (user) {
              user.compName = user.user_detail.company_name;
              return user;
            }),
          "compName"
        );
        this.getConnectingMembers();
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      }
    },

    async getConnectingMembers() {
      try {
        this.connecting_participants = customSortMixChars(
          this.members
            .filter(
              (elem) =>
                !this.online_participants.find(
                  ({ userId }) => elem.id == userId
                ) && elem.user_token
            )
            .map(function (user) {
              user.compName = user.user_detail.company_name;
              return user;
            }),
          "compName"
        );
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      }
    },

    async searchParticipants() {
      await this.initialize();
      if (this.search === "") {
        return;
      } else {
        this.online_participants = customSortMixChars(
          this.online_participants.filter(
            (data) =>
              data.repName.toUpperCase().includes(this.search.toUpperCase()) ||
              data.compName.toUpperCase().includes(this.search.toUpperCase())
          ),
          "compName"
        );
        this.offline_participants = customSortMixChars(
          this.offline_participants
            .filter(
              (data) =>
                data.user_detail &&
                ((data.user_detail.representative_name &&
                  data.user_detail.representative_name
                    .toUpperCase()
                    .includes(this.search.toUpperCase())) ||
                  (data.user_detail.company_name &&
                    data.user_detail.company_name
                      .toUpperCase()
                      .includes(this.search.toUpperCase())))
            )
            .map(function (user) {
              user.compName = user.user_detail.company_name;
              return user;
            }),
          "compName"
        );
        this.connecting_participants = customSortMixChars(
          this.connecting_participants
            .filter(
              (data) =>
                data.user_detail &&
                ((data.user_detail.representative_name &&
                  data.user_detail.representative_name
                    .toUpperCase()
                    .includes(this.search.toUpperCase())) ||
                  (data.user_detail.company_name &&
                    data.user_detail.company_name
                      .toUpperCase()
                      .includes(this.search.toUpperCase())))
            )
            .map(function (user) {
              user.compName = user.user_detail.company_name;
              return user;
            }),
          "compName"
        );
      }
    },
  },
  destroyed() {
    window.location.reload();
  },
};
</script>
